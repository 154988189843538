import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default class extends Controller {
  static targets = ["slider", "nextButton", "prevButton", "pagination"]

  declare readonly sliderTarget: HTMLElement
  declare readonly nextButtonTarget: HTMLElement
  declare readonly prevButtonTarget: HTMLElement
  declare readonly paginationTarget: HTMLElement

  connect() {
    new Swiper(this.sliderTarget, {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      },
    });
  }
}
