import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidemenu"]

  declare sidemenuTarget: HTMLElement

  open() {
    this.sidemenuTarget.classList.add('open');
  }

  close() {
    this.sidemenuTarget.classList.remove('open');
  }
}

