import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

declare global {
  interface Window {
    dataLayer: any;
  }
}
export default class extends Controller {
  static values = { buildingId: String, buildingName: String }
  static targets = ["likeButton", "unLikeButton"]

  declare readonly buildingIdValue: string
  declare readonly buildingNameValue: string
  declare readonly likeButtonTarget: HTMLButtonElement
  declare readonly unLikeButtonTarget: HTMLButtonElement

  connect() {
    this.updateButtons()
  }

  like(event: Event) {
    event.preventDefault();

    const favoriteCookieValues = this.parseCurrentFavoriteCookies()
    if (favoriteCookieValues.includes(this.buildingIdValue)) { return }

    favoriteCookieValues.push(this.buildingIdValue)
    Cookies.set("favorite", favoriteCookieValues.join(','), { expires: 90, path: '/' })

    this.updateButtons()
  }

  unlike(event: Event) {
    event.preventDefault();

    let favoriteCookieValues = this.parseCurrentFavoriteCookies()
    favoriteCookieValues = favoriteCookieValues.filter(id => id !== this.buildingIdValue)
    Cookies.set("favorite", favoriteCookieValues.join(','), { expires: 90, path: '/' })

    this.updateButtons()
    // お気に入り解除の際GTMのイベントはpushしない
  }

  parseCurrentFavoriteCookies() {
    const favoriteCookiesRaw = Cookies.get("favorite")
    return favoriteCookiesRaw ? favoriteCookiesRaw.split(',').map(id => id.trim()) : []
  }

  updateButtons() {
    const favoriteCookieValues = this.parseCurrentFavoriteCookies()

    if (favoriteCookieValues.includes(this.buildingIdValue)) {
      this.likeButtonTarget.classList.add("hide")
      this.unLikeButtonTarget.classList.remove("hide")
    } else {
      this.likeButtonTarget.classList.remove("hide")
      this.unLikeButtonTarget.classList.add("hide")
    }
  }
}
