import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["detail", "open", "close"]

  declare readonly detailTarget: HTMLElement
  declare readonly openTarget: HTMLElement
  declare readonly closeTarget: HTMLElement

  open(event: Event) {
    event.preventDefault()
    this.detailTarget.classList.add("open")
    this.closeTarget.classList.remove("hide")
    this.openTarget.classList.add("hide")
  }

  close(event: Event) {
    event.preventDefault()
    this.detailTarget.classList.remove("open")
    this.closeTarget.classList.add("hide")
    this.openTarget.classList.remove("hide")
  }
}
